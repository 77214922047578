<template>
  <div
    class="app-avatar"
    :class="hasImageLoaded ? '' : '-placeholder'"
  >
    <div class="frame">
      <div
        v-if="imageUri"
        class="image-containe"
      >
        <img
          ref="image"
          class="image"
          :class="imageClass"
          :src="imageSrc"
          alt="メンバー"
          @load="setImageClass"
        />
      </div>
      <app-img
        v-else
        class="image"
        src="/ui/appAvatar/avatar-placeholder.png"
        alt="avator-placeholder"
      />
    </div>
    <app-img
      v-if="isAnniversary"
      class="badge"
      src="/pages/company/badge-anniversary.png"
      alt="anniversary"
    />
  </div>
</template>

<script setup lang="ts">
const { $client } = useNuxtApp()
import useFlashMessage from '~/composables/useFlashMessages'
const { showFlashMessage } = useFlashMessage()
const $config = useRuntimeConfig()
interface Props {
  imageUri: string | null
  isAnniversary: boolean
}

const props = withDefaults(defineProps<Props>(), {
  imageUri: null,
  isAnniversary: false,
})

const { imageUri } = toRefs(props)

const imageSrc = ref('')
const imageClass = ref('')
const hasImageLoaded = computed(() => props.imageUri && imageClass.value)

const fetchData = async (): Promise<void> => {
  if (!props.imageUri) {
    return
  }
  if ($config.public.APP_ENV === 'demo') {
    imageSrc.value = props.imageUri
  } else {
    if (typeof props.imageUri === 'string') {
      const url = props.imageUri?.replace('/avatars/', '')
      await $client.v3.avatars
        ._url(url)
        .$get()
        .then(response => {
          imageSrc.value = window.URL.createObjectURL(response)
        })
        .catch(function (error) {
          showFlashMessage('fail', error?.response?.data?.message)
        })
    }
  }
}
onMounted(fetchData)

onUnmounted(() => {
  if (imageSrc.value && $config.public.APP_ENV !== 'demo') {
    window.URL.revokeObjectURL(imageSrc.value)
  }
})

const setImageClass = (e: Event): void => {
  const image = e.target as HTMLImageElement
  if (image.height > image.width) {
    imageClass.value = '-tall'
  } else {
    imageClass.value = '-wide'
  }
}

watch(imageUri, async (newVal, _oldVal) => {
  if (newVal) {
    await fetchData()
  }
})
</script>

<style lang="scss" scoped>
.app-avatar {
  position: relative;

  > .frame {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 27%;
    > .image-containe {
      height: 100%;
      > .image {
        width: 100%;
        height: 100%;
        object-fit: cover;

        &.-tall {
          width: 100%;
        }

        &.-wide {
          height: 100%;
        }
      }
    }
  }
  &.-placeholder {
    > .frame {
      display: flex;
      align-items: center;
      background-color: $light-gray-color;
      > .image {
        display: inherit;
        width: 50%;
        height: auto;
        margin: 0 auto;
        object-fit: contain;
      }
    }
  }

  > .badge {
    position: absolute;
    top: -10px;
    right: -16px;
    width: 35px;
  }
}
</style>
